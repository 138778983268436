<template>
  <div class="container">
    <van-cell-group inset :border="false">
      <van-field center :border="false" label-align="right" readonly label-width="65" v-model="player.steam_account" label="SteamID" />
    </van-cell-group>
    <van-button block color="var(--app-button-bg-color)" style="margin-top: 15px" @click="handleUnBind">解绑SteamID</van-button>
  </div>
</template>
<script>
export default {
  name: 'UnBindSteam',
}
</script>
<script setup>
import { Form, Field, CellGroup, Button, Toast } from 'vant'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { unBindSteam } from '@/api/user'
import { redirectPage } from '@/utils/common'
const VanForm = Form
const VanField = Field
const VanCellGroup = CellGroup
const VanButton = Button
const store = useStore()
const router = useRouter()
const player = ref({})

onMounted(() => {
  initData()
})

const initData = () => {
  store.dispatch('user/getInfo').then(res => {
    if (!res.data.user.player_id) {
      Toast({
        message: '未绑定SteamID，即将前往绑定页面！',
        onClose: () => {
          router.replace({ name: 'BindSteam' })
        },
      })
    }
  })
  store.dispatch('player/getInfo').then(res => {
    if (res.data.player) {
      player.value = res.data.player
    }
  })
}

const handleUnBind = () => {
  unBindSteam().then(res => {
    Toast.success({
      message: res.message,
      forbidClick: true,
      onClose: () => {
        redirectPage()
      },
    })
  })
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}
</style>
